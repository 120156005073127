.main-content {
  /* Style the main content area where the sections reside */
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(
    100vh - 0px
  ); /* Set the height to fill remaining viewport height */
  /* Add additional styles as needed */
}
section {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  overflow-y: auto;

/* Additional styles */
}

/* section:nth-child(2){
height:200dvh;  jun section ma content 1 page vanda baadi vako lai height halne yo static approach  */

  
.footer{
  background-color: rgb(19, 23, 23);
  height:150px
}
footer h1{
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 100px;
  color:whitesmoke
}

@media screen and (max-width:679px) {

  section{
     
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 30px;
      overflow-y: auto;
    
    
      /* Additional styles */
    
  }
  .footer{
    background-color: rgb(19, 23, 23);
    height:70px
  }
  footer h1{
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    color:whitesmoke;
    font-size: medium;
  }
}