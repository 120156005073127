/* Nav.css */
.navBar {
    display: flex;
    flex-direction: row;
    justify-content: center; 
     width: 100%;
     height: 70px;
     background-color: white;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    z-index: 1;
    position: fixed;
    }
  
  .navBar a {
    text-decoration: none;
    color: black;
    padding: 20px;
    font-size: 25px;
    align-items: center;
    font-weight: bolder;
    display: block; 
    cursor: pointer;
  }
  .navBar a::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: black;
  border-radius: 5px;
  transform: scaleX(0);
  transition: all 0.6s ease;
  }
  .navBar a::before{
    bottom:0;
    left:0;
  }
  .navBar a:hover::before{
    transform: scaleX(1);
  }
  /* .navBar a:hover {
    color: blue;
  } */
 
  #mobile{
    display: none;
  }
  
  #mobile i{
    text-decoration: none;
    color: black;
    padding: 20px;
    font-weight: bolder;
    display: block; /* Ensures links take up full width */
    cursor: pointer;
    }

    @media screen and (max-width:769px) {
      .navBar {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; 
        align-items: flex-start;
         width: 100%;
         height: auto;
         background-color: white;
         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        position: fixed;
        transition: top 0.3s ease-in-out;
        top: 0;
        z-index: 1;
        
        }
        .navBar.active{
          top:-280px
        }
        #mobile{
          display: flex;
          position: fixed;
          top:0px;
          right:10px;
        }
        
    }