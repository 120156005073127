.projects-container {
  padding-top: 50px;
  height: auto;
  border-radius: 20px;
  gap:20px;
  display: flex;
  flex-direction: column;
}
.projects-container h1 {
  color: blue;
}
.project1,
.project0,
.project3 {
  padding: 50px 20px;
  display: flex;
  flex-direction: row;
  width: auto;
  border-radius: 20px;
  background-color: #fff;

}
.project2
{
  padding: 50px 20px;
  display: flex;
  flex-direction: row-reverse;
  width: auto;
  border-radius: 20px;
  background-color: #fff;
}
.project-img {
  height: 400px;
  width: 65%;

  min-height: 250px;
  min-width: 400px;
  background-image: url("../../images/Project1.png");
  background-size: cover;
  background-position: center;

  border-radius: 20px;
  z-index: 0;
}
.project-img-2 {
  height: 400px;
  width: 65%;

  min-height: 250px;
  min-width: 400px;
  background-image: url("../../images/Project2.png");
  background-size: cover;
  background-position: center;

  border-radius: 20px;
  z-index: 0;
}
.project-img-0 {
  height: 400px;
  width: 65%;

  min-height: 250px;
  min-width: 400px;
  background-image: url("../../images/project0.png");
  background-size: cover;
  background-position: center;

  border-radius: 20px;
  z-index: 0;
}
.project-img-3 {
  height: 400px;
  width: 65%;

  min-height: 250px;
  min-width: 400px;
  background-image: url("../../images/project3.png");
  background-size: cover;
  background-position: center;

  border-radius: 20px;
  z-index: 0;
}
.project-intro {
  padding: 50px;
  width: 35%;
  text-align: center;
  font-family: "Inter";
}
.project-intro-head {
  font-size: x-large;
  font-weight: bolder;
}

.project-intro-tail {
  padding: 20px;
  width: auto;
  font-size: 25px;
}
.react {
  justify-content: center;
  flex-direction: row;
  display: flex;
  gap: 20px;
}

.react div {
  background-color: whitesmoke;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 120px;
}
.links {
  padding: 20px;
  display: flex;
  gap: 25px;
  justify-content: center;
  font-size: 30px;
  color: black;
}
.links a {
  color: black;
  text-decoration: none;
}
.links a:hover{
  color:blue;
}
@media screen and (max-width:679px) {
  .project1 {
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
  }
  .project2 {
    padding: 50px 10px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    border-radius: 20px;
  }
  .project0 {
    padding: 50px 10px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    border-radius: 20px;
  }
  .project3 {
    padding: 50px 10px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    border-radius: 20px;
  }
  .project-intro{
    width: auto;
  }
  .project-img,
  .project-img-2,
  .project-img-0,
  .project-img-3


  {
    width: auto;
    min-width: 200px;
  }
}
